import React, { useState, useEffect } from "react"
import { Redirect } from '@reach/router'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import ReactWOW from "react-wow"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"
import Unsubscribe from "../assets/images/unsubscribe-icon.svg"
import UnsubscribeSuccess from "../assets/images/unsubscribe-success.svg"
import UnsubscribeCancel from "../assets/images/unsubscribe-cancel.svg"
import axios from "axios"
import Loader from "../components/Index/Loader"


const UnsubscribePage = () => {
  const [unsubscribeChoice, setunsubscribeChoice] = useState(false);
  const [choiceSelected, setchoiceSelected] = useState(false);
  const [loading, setLoading] = useState(false)
  const [originId, setOriginId] = useState("");
  const [origin, setOrigin] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const originId = new URL(window.location.href).searchParams.get("id")
    const source = new URL(window.location.href).searchParams.get("source")
    const email = new URL(window.location.href).searchParams.get("email")
    setOriginId(originId);
    setOrigin(source);
    setEmail(email);
    console.log("state", originId)
    if (!originId || !source || !email)
      window.location = '/';
  }, [])


  const UnsubscribeMe = async value => {
    console.log("originId", originId, origin, email);
    try {
      setLoading(true)
      const unsubscribeDto = {
        "Origin": origin,
        "OriginId": originId,
        "UserEmail": email
      }
      console.log(unsubscribeDto)
      const res = await axios.post(
        `${process.env.GATSBY_BACKEND_API_URL}/Unsubscribe`,
        unsubscribeDto,
        {}
      )
      console.log("success");
      setLoading(false)
      setchoiceSelected(true);
      setunsubscribeChoice(true);
    } catch (error) {
      console.log("error", error);
      setchoiceSelected(false);
      setLoading(false)
    }
  }

  const KeepSubscription = () => {
    console.log("keep subscription");
    setchoiceSelected(true);
    setunsubscribeChoice(false);
  }

  return (
    <Layout>
      {loading ? <Loader /> : null}
      <SEO
        title="About Oxygen | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      {(() => {

        if (!choiceSelected) {
          return (
            <div className="home-hero-banner mb-md-5 unsubscribe-page">
              <div className="container hero mt-5 mb-5">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-10 col-lg-10">
                    <div className="main-banner-content">
                      <h1 className="text-center">
                        Are you sure you want to<br></br>unsubscribe?
                      </h1>
                      <ReactWOW delay=".2s" animation="fadeInDown">
                        <div className="main-banner-image mt-4 mt-lg-5">
                          <img loading="eager" src={Unsubscribe} alt="banner" />
                          {/* <StaticImage
                                  src="../../assets/images/hero.svg"
                                  placeholder="tracedSVG"
                                  layout="fullWidth"
                                  quality="100"
                                /> */}
                        </div>
                      </ReactWOW>

                      <h2 className="text-center mt-4 mt-lg-5">If you unsubscribe, you will stop receiving Oxygen listing follow up emails.</h2>
                      <ReactWOW delay=".2s" animation="fadeInUp">
                        <div className="row mb-5">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="btn-box mt-4 text-center">
                              <button
                                className="default-btn primary-btn btn-lg mt-2 mt-md-0 w-100"
                                onClick={KeepSubscription}
                              >
                                Keep receiving mails
                              </button>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="btn-box mt-4 text-center">
                              <button
                                className="default-btn outline-btn btn-lg mt-2 mt-md-0 w-100"
                                onClick={UnsubscribeMe}
                              >
                                Unsubscribe
                              </button>
                            </div>
                          </div>
                        </div>
                      </ReactWOW>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          )
        }

        return null;

      })()}

      {(() => {
        if (choiceSelected && unsubscribeChoice) {
          return (
            <div className="home-hero-banner mb-md-5 unsubscribe-page">
              <div className="container hero mt-5 mb-5">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-10 col-lg-10">
                    <div className="main-banner-content">
                      <h1 className="text-center">
                        Unsubscribed<br></br>Successfully!
                      </h1>
                      <ReactWOW delay=".2s" animation="fadeInDown">
                        <div className="main-banner-image mt-4 mt-lg-5">
                          <img loading="eager" src={UnsubscribeSuccess} alt="banner" />
                        </div>
                      </ReactWOW>

                      <h2 className="text-center mt-4 mt-lg-5">You will no longer receive email notifications.</h2>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          )
        }
        else if (choiceSelected && !unsubscribeChoice) {
          return (
            <div className="home-hero-banner mb-md-5 unsubscribe-page">
              <div className="container hero mt-5 mb-5">
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-10 col-lg-10">
                    <div className="main-banner-content">
                      <h1 className="text-center">
                        Thanks for staying<br></br>with us!
                      </h1>
                      <ReactWOW delay=".2s" animation="fadeInDown">
                        <div className="main-banner-image mt-4 mt-lg-5">
                          <img loading="eager" src={UnsubscribeCancel} alt="banner" />
                        </div>
                      </ReactWOW>
                      <h2 className="text-center mt-4 mt-lg-5">You will continue receiving our email notifications.</h2>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          )
        }
      })()}

      <Footer />
    </Layout>
  )
}

export default UnsubscribePage
